import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history', // ’hash'
  base: '/',
  routes: [
    // {
    //   path: "/",
    //   name: "index",
    //   component: () => import('@/views/home/homeIndex'),
    // },
    {
      path: "/",
      name: "home",
      component: () => import('@/views/home/index'),
    },
    {
      path: "/details",
      name: "details",
      component: () => import('@/views/details/index'),
    }
  ]
});