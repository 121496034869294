
// 线下
/*
let BASE_URL = 'http://192.168.2.23:8080'//接口地址
let TENANT_ID = '1623156616012300289'//默认租户id
let url = 'http://192.168.2.23:18082'//图片地址
let minWidth = '(min-width: 500px)'//移动端、pc端自适应切换宽度
let putOnFile='京公网安备11000002000001号'//备案号
*/
/**/
// 生产
let BASE_URL = 'https://www.xun-pin.cn:9443' // 接口地址
let TENANT_ID = '1674319414526156802'        // 默认租户id
let url = 'https://file.xun-pin.cn/pic'      // 图片地址
let minWidth = '(min-width: 500px)'          // 移动端、pc端自适应切换宽度
let putOnFile='Copyright 2017-2023 版权所有  鲁ICP备13003837号-2'   // 备案号


//备案号
// let TENANT_ID = '1633671589801238529'
export default { BASE_URL, TENANT_ID, url, minWidth,putOnFile };
