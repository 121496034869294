import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import router from './router'
import config from '@/config.js'

import 'element-ui/lib/theme-chalk/index.css'
import './style/global.css'
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.config = config;
new Vue({
  router,
  render: h => h(App),
  mounted() {
     document.dispatchEvent(new Event("render-event"));
   },
}).$mount('#app')
